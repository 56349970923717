import React from "react"
import { PhoneIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"


const Section03Costos: React.FC = () => {
    return (
        <section className="container   max-w-4xl 2xl:max-w-6xl mx-auto pt-14 md:pt-32 lg:pt-20 2xl:pt-28 px-8">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="font-body text-gray md:mr-2.5">
                    <h1 className="text-heading-2 -ml-0.5 2xl:py-4 pb-8">Establece un presupuesto que se adapte a tu organización.</h1>
                    <p className="text-base">
                        Evofinder está totalmente libre de pagos anuales por concepto de conectividad, licenciamiento,  garantizando siempre su operación permanente.
                    </p>
                    <br></br>
                    <p className="text-sm">
                        Contacta un especialista en SUMTECH y diseña tu plan de
                        implementación personalizado.
                    </p>
                    <div className="inline-flex text-white mb-4 mt-6">
                        <div className="flex items-center text-center text-black px-2 py-2 text-base">
                            <StaticImage
                                alt="profile"
                                src="../../images/profile.png"
                                quality={95}
                                imgStyle={{objectFit: 'contain', height: "32px", width: "32px", borderRadius:"50%"}}
                                width={102}
                                />
                            <div className="text-sm">
                                Nuestros expertos pueden ayudarte a configurar una implementación exitosa de Evofinder en tu organización,   sistema acreditado por ANAB ISO 17025.
                            </div>
                        </div>

                    </div>
                    &nbsp;
                    <a href="tel:528183334055" className="inline-flex font-body text-red h-6">
                        <PhoneIcon className="block w-8 pr-2" aria-hidden="true" />
                        +52 81 8333 4055
                    </a>
                    &nbsp;
                    <p className="text-xs">
                        Atención disponible de lunes a viernes, de 9 a 17 hs.
                        Tiempo de espera promedio para hablar con un representante: menos de
                        un minuto
                    </p>
                </div>
                <div className="pt-6 flex justify-center  md:ml-2.5">
                    <StaticImage
                        alt="Costos Evofinder"
                        src="../../images/costos-evofinder.png"
                        quality={95}
                        imgStyle={{ objectFit: 'contain' }}
                    />
                </div>
            </div>
        </section>
    )
}

export default Section03Costos

