import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Section03Costos from "../components/costos/Section03"


const SecondPage = () => (
  <Layout>
    <Seo 
    title={"Obtén resultados con un presupuesto adecuado para tu organización"}
    description={"Establece un presupuesto que se adapte a tu organización. Con Evofinder, tu inversión se traduce en  la mejor relación costo-beneﬁcio jamás ofrecida."}
     />
    <Section03Costos />
  </Layout>
)

export default SecondPage
